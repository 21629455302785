
import { reactive, defineComponent, ref } from 'vue'
import { stringLimit } from '@/utils/string'
import { formatterDollars } from '@/utils/formatter'
export default defineComponent({
  props: {
    dataSet: {
      type: Object,
    },
    costBy: {
      type: String,
    },
    colors: {
      type: Array,
      default: () => [],
    },
    sizes: {
      type: Array,
      default: () => [],
    },
    values: {
      type: Array,
      default: () => [],
    },
    productCode: {
      type: String,
    },
    designs: {
      type: Array,
      default: () => [],
    },
    catalogType: {
      type: String,
    },
  },
  setup(props) {
    const form = reactive({
      name: '',
      value: '',
      code: '',
      cost: '',
    })
    const formVariant = reactive({
      variantCode: '',
      color_code: '',
      size_code: '',
      status: '',
      description: '',
      cost: '',
    })
    const cost = ref<string | undefined>(props.costBy)
    const colorsValue = ref<any[]>([...props.colors])
    const sizesValue = ref<any[]>([...props.sizes])
    const valuesValue = ref<any[]>([...props.values])
    const dialog = ref(false)
    const options = ref([
      {
        title: 'Cost by size',
        value: 'size',
      },
      {
        title: 'Cost by color',
        value: 'color',
      },
    ])
    const type = ref<string>('')
    const columns = [
      {
        title: 'Variant code',
        dataIndex: 'catalogVariantCode',
      },
      {
        title: 'Color',
        dataIndex: 'colorCode',
      },
      {
        title: 'Size',
        dataIndex: 'sizeCode',
      },
      {
        title: 'Status',
        dataIndex: 'inStock',
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Cost',
        dataIndex: 'cost',
      },
    ]
    const catalogAttribute = ref<any>(null)
    const catalogVariant = ref<any>(null)
    return {
      stringLimit,
      formatterDollars,
      form,
      dialog,
      options,
      type,
      cost,
      sizesValue,
      colorsValue,
      valuesValue,
      columns,
      catalogAttribute,
      formVariant,
      catalogVariant,
    }
  },
})
