import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_table, {
        data: _ctx.dataSet,
        stripe: "",
        fit: "",
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column) => {
            return (_openBlock(), _createBlock(_component_el_table_column, {
              key: column.dataIndex,
              prop: column.dataIndex,
              label: column.title,
              "tooltip-effect": column.dataIndex,
              "min-width": column.minWidth,
              class: "font-bold"
            }, {
              default: _withCtx((scope) => [
                (column.dataIndex === 'position')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(`${
                  _ctx.options.find(s => s.value === scope.row[column.dataIndex])
                    .title
                }`), 1))
                  : (column.dataIndex === 'additional_cost')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, " $" + _toDisplayString(scope.row[column.dataIndex]), 1))
                    : (
                column.dataIndex === 'template' ||
                column.dataIndex === 'mockupTemplate'
              )
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          (
                  scope.row[column.dataIndex] &&
                  scope.row[column.dataIndex] !== ''
                )
                            ? (_openBlock(), _createElementBlock("a", {
                                key: 0,
                                class: "text-primary",
                                href: "#",
                                onClick: ($event: any) => (
                  _ctx.onDownloadTemplate(_ctx.imageUrl(scope.row[column.dataIndex]))
                )
                              }, " Download ", 8, _hoisted_4))
                            : _createCommentVNode("", true)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(scope.row[column.dataIndex]), 1))
              ]),
              _: 2
            }, 1032, ["prop", "label", "tooltip-effect", "min-width"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["data"])
    ])
  ]))
}