import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c95e18d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-start" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_el_table, {
        data: _ctx.valuesValue,
        stripe: "",
        style: {"width":"100%"},
        fit: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            prop: "catalogVariantCode",
            label: "Variant code",
            "min-width": 10
          }),
          _createVNode(_component_el_table_column, {
            prop: "color",
            label: "Color",
            "min-width": 15
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_1, [
                (scope.row.color.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "w-4 h-4 mr-1 bg-color-variant",
                      style: _normalizeStyle(`--color:${scope.row.color.value}`)
                    }, null, 4))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "w-4 h-4 mr-1 bg-color-variant",
                      style: _normalizeStyle(`--color:${scope.row.color.name}`)
                    }, null, 4)),
                _createElementVNode("div", null, _toDisplayString(scope.row.color.name), 1)
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            prop: "size",
            label: "Size",
            "min-width": 10
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(scope.row.size.name), 1)
            ]),
            _: 1
          }),
          (_ctx.catalogType === 'pod')
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 0,
                label: "Design Qty",
                "min-width": 10
              }, {
                default: _withCtx((scope) => [
                  (scope.row.designs?.length === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.designs?.length), 1))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createElementVNode("p", null, _toDisplayString(scope.row.designs?.length != 0 ? scope.row.designs.length : ''), 1)
                      ]))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.catalogType === 'pod')
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 1,
                label: "Design",
                "min-width": 10
              }, {
                default: _withCtx((scope) => [
                  (scope.row.designs?.length === 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.designs, (item) => {
                          return (_openBlock(), _createElementBlock("p", { key: item }, _toDisplayString(item.name), 1))
                        }), 128))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.designs, (item) => {
                          return (_openBlock(), _createElementBlock("p", { key: item }, _toDisplayString(item), 1))
                        }), 128))
                      ]))
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.catalogType === 'pod')
            ? (_openBlock(), _createBlock(_component_el_table_column, {
                key: 2,
                label: "Design Cost",
                "min-width": 10
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.formatterDollars(scope.row.designCost)), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_table_column, {
            prop: "cost",
            label: "Cost",
            "min-width": 10
          }, {
            default: _withCtx((scope) => [
              _createTextVNode(_toDisplayString(_ctx.formatterDollars(scope.row.cost)), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Ship US",
            "min-width": 20
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "shipUsFirst",
                label: "First",
                "min-width": 10
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.formatterDollars(scope.row.shipUsFirst)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                prop: "shipUsAdditional",
                label: "Additional",
                "min-width": 10
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.formatterDollars(scope.row.shipUsAdditional)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "Ship ROW (Rest of world)",
            "min-width": 20
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_table_column, {
                prop: "shipRowFirst",
                label: "First",
                "min-width": 10
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.formatterDollars(scope.row.shipRowFirst)), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_el_table_column, {
                prop: "shipRowAdditional",
                label: "Additional",
                "min-width": 10
              }, {
                default: _withCtx((scope) => [
                  _createTextVNode(_toDisplayString(_ctx.formatterDollars(scope.row.shipRowAdditional)), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data"])
    ])
  ]))
}