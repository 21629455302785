
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import DesignTemplate from '@/pages/seller/catalog/components/DesignTemplate.vue'
import Variant from '@/pages/seller/catalog/components/Variant.vue'
import UploadImage from '@/components/common/UploadImage.vue'
import { reactive, defineComponent, computed, ref } from 'vue'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import useBaseCostReponsitories from '@/repositories/useBaseCostReponsitories'
import { useRoute } from 'vue-router'
export default defineComponent({
  components: { sectionLayoutContent, DesignTemplate, UploadImage, Variant },
  name: 'Detail Catalog',
  setup() {
    const breadcrumbs = [
      {
        text: 'Catalog',
        link: 's.catalogs',
        params: null,
      },
      {
        text: 'Detail Catalog',
        link: '',
        params: null,
      },
    ]
    const content = computed(() => {
      return { breadcrumbs }
    })
    const route = useRoute()
    const catalog = reactive<any>({
      name: '',
      categories: '',
      productCode: '',
      description: ``,
      variants: [],
      mockupGallery: [],
      colors: [],
      sizes: [],
      designs: [],
      production: '',
      shipping: '',
    })

    const info = JSON.parse(localStorage.getItem('info') || '')

    const isLoading = ref(true)
    const activeName = ref('description')

    const fetchData = async () => {
      isLoading.value = true
      const { getCatalog } = userCatalogRepositories()
      const { data } = await getCatalog(route.params.id)
      Object.assign(catalog, data)

      const { getBaseCost } = useBaseCostReponsitories()
      let res = await getBaseCost(info.sellerTeam?.basecost.id as string)

      if (res && res.variantPriceMapping) {
        catalog.variants.forEach((s: any) => {
          if (res.variantPriceMapping[s._id] !== null) {
            s.cost = res.variantPriceMapping[s._id]
          }
        })
      }

      isLoading.value = false
    }
    const callbackMockup = (setData: any, files: string[]) => {
      setData.mockupGallery = files
    }
    fetchData()

    const showOrHideDescription2 = () => {
      const dots = document.getElementById('dots') as any
      const moreText = document.getElementById('more') as any
      const btnText = document.getElementById('myBtn') as any

      if (dots.style.display === 'none') {
        dots.style.display = 'inline'
        btnText.innerHTML = 'Hide'
        moreText.style.display = 'none'
      } else {
        dots.style.display = 'none'
        btnText.innerHTML = 'Show'
        moreText.style.display = 'inline'
      }
    }

    return {
      content,
      catalog,
      isLoading,
      route,
      callbackMockup,
      showOrHideDescription2,
      activeName,
    }
  },
})
