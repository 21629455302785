
// import { Edit, Delete, CloseBold } from '@element-plus/icons'
// import UploadImage from '@/components/common/UploadImage.vue'
import { imageUrl } from '@/utils/image'
import { reactive, defineComponent, ref } from 'vue'
export default defineComponent({
  components: {},
  props: {
    dataSet: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const columns = [
      {
        title: 'Position',
        dataIndex: 'position',
        minWidth: 10,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        minWidth: 10,
      },
      {
        title: 'Width',
        dataIndex: 'width',
        minWidth: 10,
      },
      {
        title: 'Height',
        dataIndex: 'height',
        minWidth: 10,
      },
      {
        title: 'Format',
        dataIndex: 'format',
        minWidth: 10,
      },
      {
        title: 'DPI',
        dataIndex: 'dpi',
        minWidth: 10,
      },
      {
        title: 'Template',
        dataIndex: 'template',
        minWidth: 10,
      },
      {
        title: 'Mockup',
        dataIndex: 'mockupTemplate',
        minWidth: 10,
      },
      // {
      //   title: 'Additional Cost',
      //   dataIndex: 'additional_cost',
      //   minWidth: 10,
      // },
    ]
    const dialog = ref(false)
    const options = [
      {
        title: 'Artwork 1',
        value: 'artwork-1',
      },
      {
        title: 'Artwork 2',
        value: 'artwork-2',
      },
      {
        title: 'Artwork 3',
        value: 'artwork-3',
      },
      {
        title: 'Artwork 4',
        value: 'artwork-4',
      },
    ]
    const form = reactive({
      position: '',
      name: '',
      width: '',
      height: '',
      template: '',
      additional_cost: '',
    })
    const callback = (setData: any, files: string[]) => {
      setData.template = files[0]
    }
    const close = () => {
      dialog.value = false
      Object.assign(form, {
        position: '',
        name: '',
        width: '',
        height: '',
        template: '',
        additional_cost: '',
      })
    }
    const save = () => {
      const obj = { ...form }
      emit('callback', obj, props.dataSet, status)
      dialog.value = false
      Object.assign(form, {
        position: '',
        name: '',
        width: '',
        height: '',
        template: '',
        additional_cost: '',
      })
    }
    const status = reactive({
      status: 'add',
      index: -1,
    })
    const editDesign = (value: any, i: number) => {
      Object.assign(form, value)
      dialog.value = true
      status.status = 'edit'
      status.index = i
      console.log('value', value)
    }
    const addDesign = () => {
      dialog.value = true
      status.status = 'add'
      status.index = -1
    }
    const dialogConfirm = reactive({
      status: false,
      index: -1,
    })
    const delDesign = (i: number) => {
      Object.assign(dialogConfirm, { status: true, index: i })
    }
    const cancelDelete = () => {
      Object.assign(dialogConfirm, { status: false, index: -1 })
    }
    const confirmDelete = (index: number) => {
      emit('delDesign', index)
      Object.assign(dialogConfirm, { status: false, index: -1 })
    }
    const downloadFile = (fileName: string, urlData: any) => {
      var aLink = document.createElement('a')
      aLink.download = fileName
      aLink.href = urlData
      var event = new MouseEvent('click')
      aLink.dispatchEvent(event)
    }
    const onDownloadTemplate = async (fileUrl: any) => {
      try {
        const filename = fileUrl?.substring(fileUrl.lastIndexOf('/') + 1)
        downloadFile(filename, fileUrl)
      } catch (e) {
        console.log({ e })
      }
    }
    return {
      columns,
      dialog,
      options,
      form,
      callback,
      close,
      save,
      editDesign,
      status,
      addDesign,
      delDesign,
      dialogConfirm,
      cancelDelete,
      confirmDelete,
      imageUrl,
      onDownloadTemplate,
    }
  },
})
