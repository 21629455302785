import { ref, computed, reactive, watch, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useFetchData } from '@/composables'
import { useResource } from '@/services'
import { AxiosResponse } from 'axios'
import { IBaseCostGroup, IBaseCost } from '@/interfaces/base-cost'

export default function useOrderRepositories() {
  const getBaseCostGroups = async (params: any) => {
    try {
      const { documents, fetchData } = useFetchData('basecost-groups', params)
      await fetchData()
      const data = documents.value as IBaseCostGroup[] | []
      return data
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const postBaseCostGroup = async (params: IBaseCostGroup) => {
    const { add } = useResource(`basecost-groups`)
    try {
      const res = await add(params)
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const getBaseCostGroup = async (id: string) => {
    const { get } = useResource(`basecost-groups`)
    try {
      const { data, status } = await get(id)
      if (status === 200) {
        return data
      }
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const patchBaseCostGroup = async (params: IBaseCostGroup) => {
    const { patch } = useResource(`basecost-groups/${params.id}`)
    try {
      const res = await patch(params)
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const deleteBaseCostGroup = async (id: string) => {
    const { deleteById } = useResource(`basecost-groups`)
    try {
      return await deleteById(id)
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const basecostResource = 'basecosts'
  // const basecostResource = 'http://localhost:3003/api/basecosts'

  const addBaseCost = async (params: IBaseCost) => {
    const { add } = useResource(basecostResource)
    try {
      const res = await add(params)
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const getListBaseCost = async (params: any) => {
    try {
      const { documents, fetchData } = useFetchData(basecostResource, params)
      await fetchData()
      const data = documents.value as []
      return data
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const getBaseCost = async (id: string) => {
    const { get } = useResource(basecostResource)
    try {
      const { data, status } = await get(id)
      if (status === 200) {
        return data
      }
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const updateBaseCost = async (params: IBaseCost) => {
    const { patch } = useResource(`${basecostResource}/${params.id}`)
    try {
      const res = await patch(params)
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  const deleteBaseCost = async (id: string) => {
    const { deleteById } = useResource(basecostResource)
    try {
      const { data, status } = await deleteById(id)
      if (status === 200) {
        return { data, status } as AxiosResponse
      }
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  /**
   * Loại bỏ những variantPriceMapping bị thừa
   */
  const cleanUpBaseCost = async (id: string) => {
    const { patch } = useResource(`${basecostResource}/${id}/clean-up`)
    try {
      const res = await patch({ id })
      return res
    } catch (error: any) {
      console.log(error)
      return error
    }
  }

  return {
    getBaseCostGroups,
    postBaseCostGroup,
    getBaseCostGroup,
    patchBaseCostGroup,
    deleteBaseCostGroup,
    addBaseCost,
    getListBaseCost,
    getBaseCost,
    updateBaseCost,
    deleteBaseCost,
    cleanUpBaseCost,
  }
}
